'use client';

import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';
import { toast } from 'react-toastify';
import { setLocalStorageItem } from '@repo/utils/localStorageUtils';

export default function AppInitializer() {
    const searchParams = useSearchParams();

    useEffect(() => {
        const initData = async () => {
            const affiliate = searchParams.get('source');

            // This is a typo in the query parameter name
            const emailVerification =
                searchParams.get('emailConfirmedSuccessully') ||
                searchParams.get('emailConfirmedSuccessfully');

            if (affiliate) {
                setLocalStorageItem('affiliate', affiliate);
            }

            if (emailVerification === 'true') {
                toast.success('Email successfully verified!');
            }
        };

        initData();
    }, [searchParams]);

    return null;
}
