'use client';

import { usePathname, redirect } from 'next/navigation';

export default function PublicLayout({ children, user }: { children: any; user?: any }) {
    const path = usePathname();

    const redirectToApp = user?.id && path === '/';
    if (redirectToApp) {
        return redirect('/projects');
    }

    return <>{children}</>;
}
